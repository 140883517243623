import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Iframe from "react-iframe";
import ToolBar from "./Toolbar";

import { toAbsoluteUrl } from "../../../../_metronic";
import {
    options,
    initOptions,
    confOptions,
    isInterpreter
} from "../../../components/utils/RoomUtils";
import { meetingVideo as useStyles } from "./CommonStyles";
import $ from "jquery";

import * as eventStore from "../../../store/ducks/event.duck";
import { UserRole } from "../../../components/utils/UserRole";
import MeetContainer from "../../../components/MeetContainer";
import { EventType } from "../../../components/utils/EventType";
import { jitsiMeeting, ORIGINAL_ROOMNAME } from "../../../components/JitsiMeeting";
import { API_STATUS } from "../../../crud/helper";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const JitsiMeetJS = window.JitsiMeetJS;

function ConferenceVideo(props) {
    const {
        event,
        authToken,
        isLoadingToken,
        isEndMeeting,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        localTracks,
        intl
    } = props;

    const classes = useStyles(props);

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEndMeeting]);

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ authToken:", authToken);
        console.log("🚀 ~ useEffect ~ isLoadingToken:", isLoadingToken);
        if (isLoadingToken == API_STATUS.SUCCESS && authToken) {
            startJitsiMeeting();
        }
    }, [isLoadingToken, authToken]);

    function startJitsiMeeting() {
        if (!event) {
            return;
        }

        const appId = process.env.REACT_APP_APP_ID;
        jitsiMeeting.connect(appId, authToken, event, null, {
            isMicOn: true,
            isMuted: false
        });
    }

    async function unload() {
        jitsiMeeting.unload();
    }

    // TODO:
    function handleHoverEvent(event) {}

    // TODO:
    function handleLeaveEvent(event) {}

    /**
     * Handle CameraSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    return (
        <>
            <MeetContainer className="row">
                <div className="col-md-12">
                    <div
                        id="localPlace"
                        className={classes.noVideoLocalPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                        : toAbsoluteUrl("/media/logos/logo-trans.png")
                                }
                                alt="avatar"
                            />
                        </div>

                        <ToolBar
                            event={event}
                            disableAudioInput={true}
                            disableVideoInput={true}
                            disableShare={true}
                            disableChat={true}
                            disableRaiseHand={true}
                        />

                        <div id="remotePlace"></div>
                        {/* Audio Output Setting Dialog */}
                    </div>
                </div>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        authToken: state.event.authToken,
        isLoadingToken: state.event.isLoadingToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        localTracks: state.event.localTracks
    };
};

const mapDispatchToProps = dispatch => ({
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setIsCamera: isCamera => dispatch(eventStore.actions.setIsCamera(isCamera))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConferenceVideo));
