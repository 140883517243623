import { isAudioEvent } from "../../../components/utils/audioEventUtil";
import AudioEvent from "../audio-event/AudioEvent";
import Event from "./Event";

export default function EventWrapper(props) {
    if (isAudioEvent) {
        return <AudioEvent {...props} />;
    } else {
        return <Event {...props} />;
    }
}
