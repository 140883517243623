import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic";
import SelectDeviceDialog from "../../../components/SelectDeviceDialog";
import {
    Paper,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
    FormControl,
    InputLabel,
    Select
} from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import Notify from "../../../components/Notify";
import Notice from "../../../partials/content/Notice";
import ConferenceVideo from "./ConferenceVideo";
import { meeting as useStyles } from "./CommonStyles";

import * as eventStore from "../../../store/ducks/event.duck";
import { ORIGINAL_ROOMNAME, jitsiMeeting } from "../../../components/JitsiMeeting";
const JitsiMeetJS = window.JitsiMeetJS;

function Conference(props) {
    const {
        isEvent,
        event,
        isStartMeeting,
        startMeeting,
        endMeeting,
        screenMode,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        audioInputSetting,
        audioInputDevices,
        intl
    } = props;
    const classes = useStyles(props);
    const [lang, setLang] = React.useState(ORIGINAL_ROOMNAME);
    const [volume, setVolume] = React.useState(0.5);
    const [isOriginal, setIsOriginal] = React.useState(false);

    const restart = localStorage.getItem("restart");

    useEffect(() => {
        if (isEvent && event) {
            // if (restart === "1") {
            // localStorage.removeItem("restart");
            handleStartMeeting(event.id);
            // }
        }
    }, [isEvent, event]);

    function handleStartMeeting(event_id) {
        if (lang) {
            startMeeting(event_id);
        }
    }

    function handleEndMeeting() {
        endMeeting();
    }

    function handleReset() {
        localStorage.setItem("restart", 1);
        window.location.reload();
    }

    function handleDown(event) {
        setVolume(prevState => {
            if (prevState >= 0.1) {
                return prevState - 0.1;
            } else {
                return 0.0;
            }
        });
    }

    function handleUp(event) {
        setVolume(prevState => {
            if (prevState <= 0.9) {
                return prevState + 0.1;
            } else {
                return 1.0;
            }
        });
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} value
     */
    function handleAudioInputSettingChange(newAudioInput) {
        jitsiMeeting.setAudioInputSetting(newAudioInput);
    }

    return (
        <div id="eventContainer">
            <Notice
                className={`notice__paragraph ${classes.headerContent} ${isStartMeeting &&
                    classes.headerShadowNone}`}
                id="content_header"
            >
                <div>
                    <img
                        className={classes.avatar}
                        src={
                            event.pic
                                ? process.env.REACT_APP_FILE_URL + event.pic
                                : toAbsoluteUrl("/media/logos/logo-sm.png")
                            // toAbsoluteUrl("/media/products/product1.jpg")
                        }
                        alt="avatar"
                    />
                </div>
                <Typography variant="h6" className={classes.title}>
                    {event.title}
                </Typography>
                <Typography className={classes.description} variant="subtitle1">
                    {event.detail}
                </Typography>
            </Notice>
            <div className={`container row ${classes.eventPlace}`} id="eventPlace">
                <div
                    className={`col-md-6 ${classes.control} ${screenMode < 0 &&
                        classes.fullWidthControl}`}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`col-md-6 ${classes.videoPlace}`} id="videoPlace">
                                <Notify event={event} />
                                {event && isStartMeeting ? (
                                    <ConferenceVideo
                                        event={event}
                                        roomname={lang}
                                        volume={volume}
                                        size="sm"
                                        isOriginal={isOriginal}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Portlet>
                                <PortletBody>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="center" spacing={2}>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        textAlign: "end"
                                                    }}
                                                >
                                                    {isStartMeeting ? (
                                                        <Button
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            className={classes.buttonRed}
                                                            disabled={!isStartMeeting}
                                                            onClick={handleEndMeeting}
                                                        >
                                                            {intl.formatMessage({
                                                                id: "EVENT.ACTION.END_MEETING"
                                                            })}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            className={classes.buttonRed}
                                                            disabled={isStartMeeting}
                                                            onClick={handleStartMeeting}
                                                        >
                                                            {intl.formatMessage({
                                                                id: "EVENT.ACTION.START_MEETING"
                                                            })}
                                                        </Button>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        textAlign: "start"
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        className={classes.buttonRed}
                                                        disabled={!isStartMeeting}
                                                        onClick={handleReset}
                                                    >
                                                        {intl.formatMessage({
                                                            id: "EVENT.ACTION.RESET"
                                                        })}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PortletBody>
                            </Portlet>
                        </div>
                        <div className="col-md-12">
                            <Paper className={classes.volumeControl}>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        disabled={false}
                                        className={classes.formControl}
                                    >
                                        <InputLabel htmlFor="side_audioInputSetting">
                                            {intl.formatMessage({
                                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                                            })}
                                        </InputLabel>
                                        <Select
                                            native
                                            onChange={e =>
                                                handleAudioInputSettingChange(e.target.value)
                                            }
                                            displayEmpty
                                            inputProps={{
                                                name: "side_audioInputSetting",
                                                id: "side_audioInputSetting"
                                            }}
                                            value={
                                                audioInputSetting === "" &&
                                                audioInputDevices &&
                                                audioInputDevices.length > 0
                                                    ? audioInputDevices[0].deviceId
                                                    : audioInputSetting
                                            }
                                        >
                                            {audioInputDevices.map(device => {
                                                return (
                                                    <option
                                                        key={device.deviceId}
                                                        value={device.deviceId}
                                                    >
                                                        {device.label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Paper>
                        </div>
                        <div className="col-md-12">
                            <Paper className={classes.volumeControl}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.volume}
                                    onClick={handleDown}
                                >
                                    <VolumeDownIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.volume}
                                    onClick={handleUp}
                                >
                                    <VolumeUpIcon />
                                </Button>
                                {/* <Button
                                    variant="contained"
                                    size="medium"
                                    color={`${isOriginal ? "primary" : "default"}`}
                                    className={classes.volume}
                                    onClick={handleOriginal}
                                >
                                    + Ori
                                </Button> */}
                            </Paper>
                        </div>
                    </div>
                </div>

                <SelectDeviceDialog
                    id="audioOutputSetting"
                    title={intl.formatMessage({
                        id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                    })}
                    label={intl.formatMessage({
                        id: "VIDEO.SETTING.AUDIO_OUPUTS"
                    })}
                    isOpen={openAudioOutputSettingDlg}
                    devices={audioInputDevices}
                    currentValue={audioInputSetting}
                    onChange={handleAudioInputSettingChange}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        event: state.emitterReducer.event,
        isEvent: state.emitterReducer.isEvent,
        isStartMeeting: state.event.isStartMeeting,
        screenMode: state.event.screenMode,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        audioInputSetting: state.event.audioInputSetting,
        audioInputDevices: state.event.audioInputDevices
    };
};

const mapDispatchToProps = dispatch => ({
    startMeeting: event_id => dispatch(eventStore.actions.startMeeting(event_id)),
    endMeeting: () => dispatch(eventStore.actions.endMeeting()),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Conference));
