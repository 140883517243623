import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Button,
    IconButton,
    DialogTitle,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    Radio,
    Checkbox,
    RadioGroup,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Grid
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import blue from "@material-ui/core/colors/blue";

import { toAbsoluteUrl } from "../../../../../_metronic";
import KTAvatar from "../../../../../_metronic/_assets/js/avatar";
import { upload_event_pic } from "../../../../crud/event.crud";

import * as eventsStore from "../../../../store/ducks/events.duck";
import { isAudioEvent } from "../../../../components/utils/audioEventUtil";

const useDlgStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600]
    },
    formControl: {
        minWidth: 250,
        maxWidth: 400
    },
    formLangControl: {
        minWidth: 150,
        maxWidth: 300
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    fullWidth: {
        width: "100%"
    }
}));

function Room(props) {
    const {
        room,
        languages,
        trans,
        values,
        index,
        errors,
        handleRemove,
        handleChange,
        setFieldValue,
        intl
    } = props;
    const { rooms, setRooms } = props.pstate;
    const classes = useDlgStyles();

    useEffect(() => {
        setRooms(prev => {
            const oldRooms = JSON.parse(JSON.stringify(prev));
            oldRooms.forEach((room, i) => {
                if (index === i) {
                    room.output_lang_id = values[`output_lang_id_${index}`] ?? "";
                    room.interpreter_id = values[`interpreter_id_${index}`] ?? "";
                    room.liquid_url = values[`liquid_url_${index}`] ?? "";
                    room.wss_url = values[`wss_url_${index}`] ?? "";
                }
            });
            return oldRooms;
        });
    }, [values, index, setRooms]);

    const handleAddLanguage = e => {
        setRooms([
            ...rooms,
            {
                output_lang_id: "",
                interpreter_id: "",
                liquid_url: "",
                wss_url: ""
            }
        ]);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
                <FormControl className={classes.formLangControl}>
                    <Autocomplete
                        id={`out-lang-checkbox_${index}`}
                        value={room && room.output_lang_id !== "" ? room.output_lang_id : null}
                        onChange={(e, value) => {
                            if (value) {
                                setFieldValue(`output_lang_id_${index}`, value.id);
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={intl.formatMessage({
                                    id: "EVENT.FIELD.OUTPUT_LANG"
                                })}
                                error={Boolean(errors[`output_lang_id_${index}`])}
                                helperText={errors[`output_lang_id_${index}`]}
                                placeholder=""
                            />
                        )}
                        options={languages}
                        name={`output_lang_id_${index}`}
                        getOptionLabel={option => {
                            const selecetdOption = languages.filter(r => r.id === option);
                            return selecetdOption.length > 0
                                ? selecetdOption[0].lang_label
                                : option.lang_label ?? "";
                        }}
                        getOptionSelected={(option, value) => option.id === value}
                        renderOption={option => option.lang_label}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <FormControl className={classes.formLangControl}>
                    <Autocomplete
                        id={`input-interpreter-checkbox_${index}`}
                        value={room && room.interpreter_id !== "" ? room.interpreter_id : null}
                        onChange={(e, value) => {
                            if (value) setFieldValue(`interpreter_id_${index}`, value.id);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={intl.formatMessage({
                                    id: "EVENT.FIELD.INTERPRETER"
                                })}
                                error={Boolean(errors[`interpreter_id_${index}`])}
                                helperText={errors[`interpreter_id_${index}`]}
                                placeholder=""
                            />
                        )}
                        options={trans}
                        name={`interpreter_id_${index}`}
                        getOptionLabel={option => {
                            const selecetdOption = trans.filter(r => r.id === option);
                            return selecetdOption.length > 0
                                ? selecetdOption[0].name
                                : option.name ?? "";
                        }}
                        getOptionSelected={(option, value) => option.id === value}
                        renderOption={option => option.name}
                    />
                </FormControl>
            </Grid>
            <Grid
                item
                xs={12}
                sm={2}
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                direction="row"
            >
                <IconButton
                    className={classes.button}
                    aria-label="Delete"
                    size="small"
                    onClick={e => handleRemove(index)}
                >
                    <DeleteIcon />
                </IconButton>
                <IconButton
                    className={classes.button}
                    aria-label="Add"
                    size="small"
                    onClick={handleAddLanguage}
                >
                    <AddIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl className={classes.fullWidth}>
                    <TextField
                        type="text"
                        margin="dense"
                        className="kt-width-full"
                        name={`liquid_url_${index}`}
                        label={intl.formatMessage({
                            id: "EVENT.LANG.LIQUID_URL"
                        })}
                        variant="outlined"
                        onChange={handleChange}
                        value={room.liquid_url ?? ""}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl className={classes.fullWidth}>
                    <TextField
                        type="text"
                        margin="dense"
                        className="kt-width-full"
                        name={`wss_url_${index}`}
                        label={intl.formatMessage({ id: "EVENT.LANG.WSS_URL" })}
                        variant="outlined"
                        onChange={handleChange}
                        value={room.wss_url ?? ""}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

function EventDlg(props) {
    const {
        onClose,
        open,
        eventId,
        eventValue,
        languages,
        admins,
        adminId,
        managers,
        emitters,
        trans,
        dispatch
    } = props;
    const eventAvatar = useRef(null);
    const eventBg = useRef(null);
    const [event, setEvent] = useState({ admin_id: adminId });
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        if (open && !eventAvatar.current && document.getElementById("kt_event_avatar")) {
            eventAvatar.current = new KTAvatar("kt_event_avatar");
        }

        if (open && !eventBg.current && document.getElementById("kt_event_bg")) {
            eventBg.current = new KTAvatar("kt_event_bg");
        }

        return () => {
            eventAvatar.current = null;
            eventBg.current = null;
        };
    });

    useEffect(() => {
        if (open && adminId) {
            setEvent(prev => {
                return { ...prev, admin_id: adminId };
            });
        }
    }, [open, adminId]);

    const classes = useDlgStyles();

    useEffect(() => {
        if (eventId && eventId !== -1) {
            let eventValue1 = eventValue;
            if (eventValue1.rooms && eventValue1.rooms.length > 0) {
                const event_rooms = eventValue1.rooms;
                eventValue1.input_lang_id = event_rooms[0].input_lang_id;

                setRooms(JSON.parse(JSON.stringify(eventValue.rooms)));

                eventValue.rooms.forEach((room, index) => {
                    eventValue[`output_lang_id_${index}`] = room.output_lang_id;
                    eventValue[`interpreter_id_${index}`] = room.interpreter_id;
                    eventValue[`liquid_url_${index}`] = room.liquid_url;
                    eventValue[`wss_url_${index}`] = room.wss_url;
                });
            }
            setEvent(eventValue);
        } else {
            setRooms([
                {
                    interpreter_id: "",
                    output_lang_id: "",
                    liquid_url: "",
                    wss_url: ""
                }
            ]);
        }
    }, [eventId, eventValue]);

    useEffect(() => {
        const oldEvent = event;
        Object.keys(oldEvent).forEach(function(k) {
            if (k.startsWith("interpreter_id")) {
                delete oldEvent[k];
            }
            if (k.startsWith("output_lang_id")) {
                delete oldEvent[k];
            }
            if (k.startsWith("liquid_url")) {
                delete oldEvent[k];
            }
            if (k.startsWith("wss_url")) {
                delete oldEvent[k];
            }
        });

        rooms.forEach((room, index) => {
            oldEvent[`output_lang_id_${index}`] = room.output_lang_id;
            oldEvent[`interpreter_id_${index}`] = room.interpreter_id;
            oldEvent[`liquid_url_${index}`] = room.liquid_url;
            oldEvent[`wss_url_${index}`] = room.wss_url;
        });

        setEvent(oldEvent);
    }, [rooms, event]);

    const handleClose = () => {
        setEvent({});
        setRooms([]);
        onClose();
    };

    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });

    // Submit Button Effects

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    // Handle Change Event Values

    function convertApiToLocalTime(start_datetime) {
        if (start_datetime) {
            const time = new Date(start_datetime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
            return start_datetime.split(/[\sT]+/)[0] + "T" + time;
        } else {
            return "";
        }
    }

    const handleRemove = index => {
        if (index !== -1) {
            if (rooms.length !== 1) {
                setRooms(prev => {
                    const newRooms = prev.filter((row, i) => {
                        return index !== i;
                    });
                    return newRooms;
                });
            }
        }
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-event-dialog-title">
                {intl.formatMessage({ id: "EVENTDLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{intl.formatMessage({ id: "EVENTDLG.DESC" })}</DialogContentText>
                <Formik
                    initialValues={event}
                    enableReinitialize
                    validate={values => {
                        const errors = {};
                        const _isAudioEvent = isAudioEvent;
                        if (!values.input_lang_id && !_isAudioEvent) {
                            errors.input_lang_id = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.title) {
                            errors.title = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.detail) {
                            errors.detail = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.start_datetime) {
                            errors.start_datetime = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!_isAudioEvent) {
                            for (let i = 0; i < rooms.length; i++) {
                                if (!rooms[i].interpreter_id || rooms[i].interpreter_id === "") {
                                    errors[`interpreter_id_${i}`] = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!rooms[i].output_lang_id || rooms[i].output_lang_id === "") {
                                    errors[`output_lang_id_${i}`] = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                            }
                        }

                        return errors;
                    }}
                    onSubmit={async (values, { setStatus, setSubmitting }) => {
                        if (!values.event_type) {
                            values.event_type = 1;
                        }
                        enableLoading();

                        if (!_.isNil(values.pic)) {
                            let formData = new FormData();
                            formData.append("pic", values.pic);
                            await upload_event_pic(formData)
                                .then(response => {
                                    values.pic = response.data.file_name;
                                })
                                .catch(error => {});
                        }

                        if (!_.isNil(values.bg_pic)) {
                            let formData = new FormData();
                            formData.append("bg_pic", values.bg_pic);
                            await upload_event_pic(formData)
                                .then(response => {
                                    values.bg_pic = response.data.file_name;
                                })
                                .catch(error => {});
                        }

                        setTimeout(() => {
                            if (eventId !== -1) {
                                if (values.emitter_id !== 0 && values.event_type === 1) {
                                    const emitter_id = values.emitter_id;
                                    values.emitter_name = emitters.find(
                                        emitter => emitter.id === emitter_id
                                    ).name;
                                } else {
                                    values.emitter_name = 0;
                                }

                                dispatch(eventsStore.actions.updateEvents(values));
                                disableLoading();
                                onClose();
                            } else {
                                dispatch(eventsStore.actions.addEvent(values));
                                disableLoading();
                                onClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="event_type"
                                    name="event_type"
                                    value={values.event_type ? parseInt(values.event_type) : 1}
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.CONFERENCE"
                                        })}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.MEETING"
                                        })}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={3}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.WEBINAR"
                                        })}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={4}
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({
                                            id: "EVENT.TYPE.WEB"
                                        })}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>

                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    {intl.formatMessage({
                                        id: "USER.FIELD.AVATAR"
                                    })}
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div
                                        className="kt-avatar kt-avatar--outline"
                                        id="kt_event_avatar"
                                    >
                                        <div
                                            className="kt-avatar__holder"
                                            style={{
                                                backgroundImage: `url(${
                                                    event.pic
                                                        ? process.env.REACT_APP_FILE_URL + event.pic
                                                        : toAbsoluteUrl(
                                                              "/media/logos/logo-trans.png"
                                                          )
                                                })`
                                            }}
                                        />
                                        <label
                                            className="kt-avatar__upload"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Change avatar"
                                        >
                                            <i className="fa fa-pen"></i>
                                            <input
                                                type="file"
                                                onChange={e => {
                                                    setFieldValue("pic", e.currentTarget.files[0]);
                                                }}
                                                name="pic"
                                                accept=".png, .jpg, .jpeg"
                                            />
                                        </label>
                                        <span
                                            className="kt-avatar__cancel"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Cancel avatar"
                                        >
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    {intl.formatMessage({
                                        id: "USER.FIELD.BACKGROUND"
                                    })}
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div className="kt-avatar kt-avatar--outline" id="kt_event_bg">
                                        <div
                                            className="kt-avatar__holder"
                                            style={{
                                                backgroundImage: `url(${
                                                    event.pic
                                                        ? process.env.REACT_APP_FILE_URL +
                                                          event.bg_pic
                                                        : toAbsoluteUrl(
                                                              "/media/bg/background-1.jpg"
                                                          )
                                                })`
                                            }}
                                        />
                                        <label
                                            className="kt-avatar__upload"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Change background image"
                                        >
                                            <i className="fa fa-pen"></i>
                                            <input
                                                type="file"
                                                onChange={e => {
                                                    setFieldValue(
                                                        "bg_pic",
                                                        e.currentTarget.files[0]
                                                    );
                                                }}
                                                name="bg_pic"
                                                accept=".png, .jpg, .jpeg"
                                            />
                                        </label>
                                        <span
                                            className="kt-avatar__cancel"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Cancel background image"
                                        >
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "EVENTS.TABLE_HEADER.TITLE"
                                    })}
                                    className="kt-width-full"
                                    name="title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title ? values.title : ""}
                                    helperText={errors.title}
                                    error={Boolean(errors.title)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "EVENTS.TABLE_HEADER.DETAIL"
                                    })}
                                    variant="outlined"
                                    multiline
                                    maxRows="4"
                                    className="kt-width-full"
                                    name="detail"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.detail ?? ""}
                                    helperText={errors.detail}
                                    error={Boolean(errors.detail)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "EVENT.FIELD.URL"
                                    })}
                                    variant="outlined"
                                    className="kt-width-full"
                                    name="extra_url"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.extra_url ?? ""}
                                    helperText={errors.extra_url}
                                    error={Boolean(errors.extra_url)}
                                />
                            </div>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        values.hasOwnProperty("is_passthrough")
                                                            ? !!(
                                                                  values.is_passthrough ||
                                                                  values.is_passthrough === 1
                                                              )
                                                            : true
                                                    }
                                                    onChange={handleChange}
                                                    name="is_passthrough"
                                                />
                                            }
                                            label="Passthrough"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="start_datetime-local"
                                        label={intl.formatMessage({
                                            id: "EVENTS.TABLE_HEADER.START_DATE"
                                        })}
                                        type="datetime-local"
                                        className={classes.textField}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="start_datetime"
                                        value={convertApiToLocalTime(values.start_datetime)}
                                        helperText={errors.start_datetime}
                                        error={Boolean(errors.start_datetime)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="form-group">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="admin-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.ADMIN"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="admin-checkbox-label"
                                                id="admin-checkbox"
                                                value={values.admin_id ? values.admin_id : ""}
                                                onChange={handleChange}
                                                name="admin_id"
                                            >
                                                {admins.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="manager-checkbox-label">
                                                {intl.formatMessage({
                                                    id: "EVENT.FIELD.MANAGER"
                                                })}
                                            </InputLabel>
                                            <Select
                                                labelId="admin-checkbox-label"
                                                id="manager-checkbox"
                                                value={values.manager_id ? values.manager_id : ""}
                                                onChange={handleChange}
                                                name="manager_id"
                                            >
                                                {managers.map(row => (
                                                    <MenuItem key={row.id} value={row.id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="form-group">
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        margin="normal"
                                        label={intl.formatMessage({
                                            id: "EVENT.FIELD.SUBTITLE"
                                        })}
                                        variant="outlined"
                                        className="kt-width-full"
                                        name="subtitle"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.subtitle ?? ""}
                                        helperText={errors.subtitle}
                                        error={Boolean(errors.subtitle)}
                                    />
                                </Grid>
                            </div>
                            <div className="form-group">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <Autocomplete
                                                id="inputlang-selector"
                                                value={values.input_lang_id ?? null}
                                                onChange={(e, value) => {
                                                    if (value)
                                                        setFieldValue("input_lang_id", value.id);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={intl.formatMessage({
                                                            id: "EVENT.FIELD.INPUT_LANG"
                                                        })}
                                                        error={Boolean(errors.input_lang_id)}
                                                        helperText={errors.input_lang_id}
                                                        placeholder=""
                                                    />
                                                )}
                                                options={languages}
                                                name="input_lang_id"
                                                getOptionLabel={option => {
                                                    const selecetdOption = languages.filter(
                                                        r => r.id === option
                                                    );
                                                    return selecetdOption.length > 0
                                                        ? selecetdOption[0].lang_label
                                                        : option.lang_label ?? "";
                                                }}
                                                getOptionSelected={(option, value) =>
                                                    option.id === value
                                                }
                                                renderOption={option => option.lang_label}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.formControl}>
                                            <Autocomplete
                                                id="emitter-selector"
                                                value={values.emitter_id ?? null}
                                                disabled={
                                                    values.event_type
                                                        ? !(
                                                              parseInt(values.event_type) === 1 ||
                                                              parseInt(values.event_type) === 4
                                                          )
                                                        : false
                                                }
                                                onChange={(e, value) => {
                                                    if (value)
                                                        setFieldValue("emitter_id", value.id);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={intl.formatMessage({
                                                            id: "EVENTS.TABLE_HEADER.EMITTER"
                                                        })}
                                                        placeholder=""
                                                    />
                                                )}
                                                options={emitters}
                                                name="emitter_id"
                                                getOptionLabel={option => {
                                                    const selecetdOption = emitters.filter(
                                                        r => r.id === option
                                                    );
                                                    return selecetdOption.length > 0
                                                        ? selecetdOption[0].name
                                                        : option.name ?? "";
                                                }}
                                                getOptionSelected={(option, value) =>
                                                    option.id === value
                                                }
                                                renderOption={option => option.name}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.fullWidth}>
                                            <TextField
                                                type="text"
                                                margin="dense"
                                                className="kt-width-full"
                                                name="liquid_url"
                                                label={intl.formatMessage({
                                                    id: "EVENT.LANG.LIQUID_URL"
                                                })}
                                                variant="outlined"
                                                onChange={handleChange}
                                                value={values.liquid_url ?? ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl className={classes.fullWidth}>
                                            <TextField
                                                type="text"
                                                margin="dense"
                                                className="kt-width-full"
                                                name="wss_url"
                                                label={intl.formatMessage({
                                                    id: "EVENT.LANG.WSS_URL"
                                                })}
                                                variant="outlined"
                                                onChange={handleChange}
                                                value={values.wss_url ?? ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="form-group">
                                {rooms.length > 0 &&
                                    rooms.map((room, index) => (
                                        <Room
                                            key={index}
                                            index={index}
                                            room={room}
                                            intl={intl}
                                            languages={languages}
                                            trans={trans}
                                            values={values}
                                            handleRemove={handleRemove}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            pstate={{ rooms, setRooms }}
                                            errors={errors}
                                        />
                                    ))}
                            </div>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button color="primary" onClick={handleClose}>
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

EventDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    eventId: PropTypes.any
};

export default injectIntl(connect()(EventDlg));
