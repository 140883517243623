import React from "react";
import { Route, Switch } from "react-router-dom";
import EventList from "./EventList";
import Event from "./Event";
import { isAudioEvent } from "../../../../components/utils/audioEventUtil";
import AudioEvent from "../../emitter-audio-event/AudioEvent";

export default function Events() {
    return (
        <Switch>
            <Route exact={true} path="/events" component={EventList} />
            <Route exact={true} path="/events/:id" component={isAudioEvent ? AudioEvent : Event} />
        </Switch>
    );
}
