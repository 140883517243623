import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as eventsStore from "../../../store/ducks/customers/index.duck";

import Conference from "./Conference";

function AudioEvent(props) {
    const { event, getEvent, isEvent, intl } = props;
    const event_name = props.match.params.id;
    const [currentEvent, setCurrentEvent] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const name = queryParams.get("name");
        const password = queryParams.get("password");
        getEvent(event_name, { name: name, password: password });
        window.addEventListener("keydown", handleKeyDown);
        setLoading(true);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            setLoading(false);
        };
    }, [event_name, getEvent]);

    useEffect(() => {
        if (isEvent) {
            setCurrentEvent(event);
        }
    }, [isEvent, event]);

    function handleKeyDown(event) {
        if (event.keyCode === 32) {
            const activeElement = document.activeElement;
            if (activeElement.id !== "chatInput") {
                event.preventDefault();
            }
        }
    }

    console.log(isEvent, currentEvent, loading);
    return (
        <>
            {!currentEvent ? (
                <>
                    <div className="kt-error-v3">
                        <div className="kt-error_container">
                            <p className="kt-error_subtitle">
                                {loading && isEvent
                                    ? intl.formatMessage({
                                          id: "EVENTS.EVENT_ERROR"
                                      })
                                    : ""}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <Conference event={currentEvent} />
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        event: state.customerReducer.event,
        isEvent: state.customerReducer.isEvent
    };
};

const mapDispatchToProps = dispatch => ({
    getEvent: (event_name, user) =>
        dispatch(eventsStore.actions.getLiteCustomerEvent(event_name, user))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AudioEvent));
